import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import get from "lodash.get";

import { IMPLICIT_GRID_INFO } from "~/components/layouts/utilities/grid-helpers";

import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { useFeatureLayoutContext } from "~/shared-components/FeatureLayoutContext";

import { ConditionalWrapper } from "../_utilities/components";

import { getClosestColspan } from "./LiveGraphic.helpers.js";

const getCacheBuster = (INTERVAL) =>
  Math.floor(new Date().getTime() / INTERVAL) * INTERVAL;

export const LiveGraphicScreenshot = ({
  updateClientSide = false,
  ...props
}) => {
  const INTERVAL = 30 * 1000;
  const [cacheBuster, setCacheBuster] = useState(getCacheBuster(INTERVAL));
  useEffect(() => {
    if (updateClientSide) {
      const interval = setInterval(() => {
        setCacheBuster(getCacheBuster(INTERVAL));
      }, INTERVAL);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [cacheBuster, INTERVAL, updateClientSide]);

  const breakpoints = useBreakpoints();
  const bp = breakpoints.bp || IMPLICIT_GRID_INFO[0].bp;
  const layoutCtx = useSandwichLayoutContext();
  const featureLayoutCtx = useFeatureLayoutContext();
  const layoutVars = {
    ...(layoutCtx?.layoutVars || {}),
    ...(featureLayoutCtx?.layoutVars || {})
  };

  const hasLiveImage = !!get(props, "media.liveImage", false);
  if (!hasLiveImage) return null;

  const link = get(props, `media.link.url`, undefined);
  const colspan = get(layoutVars, `--c-span-${bp}`);
  const thisColspan = getClosestColspan(colspan, props);
  const liveImage = get(props, `media.liveImage[${thisColspan}]`, {});
  const { tabs, cta } = liveImage;

  const getCard = (tab) => {
    // const { text } = tab;
    const { url: src, altText } = tab.images[0];
    const delim = /\?/.test(tab) ? "&" : "?";
    return (
      <Fragment key={src}>
        <ConditionalWrapper
          condition={link}
          wrapper={(children) => <a href={link}>{children}</a>}
        >
          {/* text && <h2 className="font-xs franklin center mb-xs">{text}</h2> */}
          <img
            className="w-100"
            src={`${src}${delim}_=${cacheBuster}`}
            alt={altText}
          />
        </ConditionalWrapper>
        {cta && link && (
          <a className="db center mt-xs bold" href={link}>
            {cta}
          </a>
        )}
      </Fragment>
    );
  };

  return getCard(tabs[0]);
};

LiveGraphicScreenshot.propTypes = {
  updateClientSide: PropTypes.bool
};
